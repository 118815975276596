(function ($) {

    "use strict";

    var settings = {};
    var element = {};
    var target = {};

    var triggerToggle = {
        init: function () {
            triggerToggle.addEvents();
        },

        addEvents: function () {
            element.on('click', triggerToggle.showHide);
            $(window).on('resize', function () {
                clearTimeout(window.resizedFinished);
                window.resizedFinished = setTimeout(function () {
                    triggerToggle.hide();
                }, 250);
            });
        },

        removeEvents: function () {
            element.off('click', triggerToggle.showHide);
            $(window).off('resize');
        },

        showHide: function () {
            target.fadeToggle(300, "linear");
        },

        hide: function () {
            target.fadeOut(100, "linear");
        },

        destroy: function () {
            triggerToggle.removeEvent();
            triggerToggle.hide();
        }
    }

    $.triggerToggle = {
        hide: function () {
            triggerToggle.hide();
        }
    }

    $.fn.triggerToggle = function (options) {

        if (!options.target) {
            return;
        }

        settings = $.extend(settings, options);
        element = $(this);
        target = $(options.target);

        triggerToggle.init();

        return this;
    };

}(jQuery));