/**
 * Important Notice:
 *
 * If Scripts like select2, owlCarousel or fancybox are needed,
 * the have to be moved from libs_notUsed to libs. Actually only
 * the flexnav files are in the folder libs.
 */

var $ = jQuery;

$(function () {
    $(".flexnav").addClass('lg-screen');
    let flexnav = $(".flexnav").flexNav();

    const swiper = new Swiper('.headerImage .swiper', {
        // Optional parameters
        loop: true,
        autoplay: {
            delay: 6000,
        },
        speed: 2000,

        // If we need pagination
        // pagination: {
        //     el: '.swiper-pagination',
        // },

        // Navigation arrows
        // navigation: {
        //     nextEl: '.swiper-button-next',
        //     prevEl: '.swiper-button-prev',
        // },

        // And if we need scrollbar
        // scrollbar: {
        //     el: '.swiper-scrollbar',
        // },
    });


    //     /* searchForm */

    //     $('.searchButton').triggerToggle({
    //         target: '.searchForm'
    //     });

    //     $('input[name=search]').on('click', function (e) {
    //         e.blur();
    //         e.preventDefault();
    //     });

});

document.querySelectorAll('a.dialog').forEach(item => {
    item.addEventListener('click', (e) => {
        var dialog = document.getElementById(item.dataset.for);
        dialog.showModal();
        dialog.querySelector('a.close').addEventListener('click', (e) => {
            dialog.close();
        });
    });
});

// Last of Type

function lastOfType(className){
    var allElemsOfType = document.getElementsByClassName(className);
    if (!allElemsOfType || !allElemsOfType.length) return null;
    else return allElemsOfType[allElemsOfType.length - 1];
}

// Countdown

if (document.querySelector('.countdown')) { 
    function calculateCountdown(targetDate) {
        const now = new Date();
        let years = targetDate.getFullYear() - now.getFullYear();
        let months = targetDate.getMonth() - now.getMonth();
        let days = targetDate.getDate() - now.getDate();
        let hours = targetDate.getHours() - now.getHours();

        // Adjust negative values
        if (hours < 0) {
            hours += 24;
            days--;
        }
        if (days < 0) {
            const prevMonth = new Date(targetDate.getFullYear(), targetDate.getMonth(), 0);
            days += prevMonth.getDate();
            months--;
        }
        if (months < 0) {
            months += 12;
            years--;
        }

        return { years, months, days, hours };
    }

    function padNumber(number) {
        return number < 10 ? '0' + number : number;
    }

    function updateCountdown() {
        const targetDate = new Date('December 31, 2030 23:59:00');
        const countdown = calculateCountdown(targetDate);

        document.getElementById('countdown-years').innerHTML = `${padNumber(countdown.years)}`;
        document.getElementById('countdown-months').innerHTML = `${padNumber(countdown.months)}`;
        document.getElementById('countdown-days').innerHTML = `${padNumber(countdown.days)}`;
        document.getElementById('countdown-hours').innerHTML = `${padNumber(countdown.hours)}`;   
    }

    setInterval(updateCountdown, 1000);
}
